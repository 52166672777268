/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  Middleware, Dispatch, MiddlewareAPI,
} from 'redux';
import {
  DeviceModelStatus, DeviceStartup, DocumentLanguage, ReportContentType, DocumentType,
  Services,
} from '@gpt/commons';
import { ContactronWizardMiddlewareActionTypes } from './types';
import {
  ContactronStationWizardState,
  contactronWizardAddModuleInstance,
  contactronWizardSetModuleHighlight,
  contactronWizardUpdateModuleInstance,
} from '../../wizards/contactronStation';
import { deviceDescriptorSelector } from '../../deviceInstances/store/deviceDataset/selector';
import { DatasetState, DatasetType } from '../../deviceInstances/store/deviceDataset/types';
import { createStationConfiguration, writeStationConfiguration } from './stationConfiguration';
import { getAppUiLanguageCode } from '../../../i18n-config';
import { deviceInstancesStoreSelector } from '../../reduxStoreSelector';
import { deviceInstanceActiveDeviceInstance } from '../../deviceInstances/store/activeDevice/selectors';
import { createReportFilename } from '../../../helpers/functions';
import { createReport } from '../../reportService';
import { updateDeviceDatasetValues } from '../../deviceInstances/store/deviceDataset';
import { DeviceInstancesState } from '../../deviceInstances/types';
import { writeActiveDeviceVariableValues } from '../../deviceInstances/middleware/activeDevice/actions';
import createWizardReportTable from './tableReport/reportTable';

const WIZARD_CONTACTRON_STATION_COMMISSIONING = 'WIZARD_CONTACTRON_STATION_COMMISSIONING';

const wizardDescSelector = (deviceInstances: DeviceInstancesState, stationInstanceId: string)
: DeviceModelStatus.Wizards.ContactronStationCommissioningType | undefined => {
  const wizardConfigDescriptor = deviceDescriptorSelector(deviceInstances, stationInstanceId, DatasetType.user, WIZARD_CONTACTRON_STATION_COMMISSIONING);
  if (wizardConfigDescriptor?.type !== DeviceModelStatus.StatusType.WizardDescriptor) {
    return undefined;
  }
  const { wizardType } = wizardConfigDescriptor;
  return wizardType.type !== DeviceModelStatus.Wizards.WizardType.CONTACTRON_STATION_COMMISSIONING
    ? undefined
    : wizardType;
};

const contactronWizardMiddleware = (): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends ContactronWizardMiddlewareActionTypes>(instanceAction: A): Promise<A> => {
  switch (instanceAction.type) {
    case 'CONTACTRON_STATION__MIDDLEWARE__ADD_MODULE': {
      const { payload } = instanceAction;
      console.log('CONTACTRON_STATION__MIDDLEWARE__ADD_MODULE', payload);
      api.dispatch(contactronWizardAddModuleInstance(payload));
      api.dispatch(writeActiveDeviceVariableValues(payload.stationInstanceId, [{
        identRef: `DEVICE${payload.module.address}_IFSM_ADDR_AKZ`,
        backupValue: undefined,
        value: payload.module.tag,
      }]));
      setTimeout(() => {
        api.dispatch(contactronWizardSetModuleHighlight(payload.module.address, false));
      }, 2000);
    }
      break;
    case 'CONTACTRON_STATION__MIDDLEWARE__UPDATE_MODULE': {
      const { payload } = instanceAction;
      console.log('CONTACTRON_STATION__MIDDLEWARE__UPDATE_MODULE', payload);
      api.dispatch(contactronWizardUpdateModuleInstance(payload));
      api.dispatch(writeActiveDeviceVariableValues(payload.stationInstanceId, [{
        identRef: `DEVICE${payload.module.address}_IFSM_ADDR_AKZ`,
        backupValue: undefined,
        value: payload.module.tag,
      }]));
    }
      break;
    case 'CONTACTRON_STATION__MIDDLEWARE__WRITE_CONFIG': {
      const { payload } = instanceAction;
      const { stationInstanceId, methodStatusRef, pinCode } = payload;

      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const wizardType = wizardDescSelector(deviceInstances, stationInstanceId);
      if (wizardType === undefined) {
        break;
      }

      const wizardState = api.getState().contactronInitWizard as ContactronStationWizardState;
      const deviceDataset = deviceInstances.instances[stationInstanceId]?.deviceDataset.user;

      const stationConfiguration = createStationConfiguration(pinCode, wizardState, wizardType, deviceDataset ?? {});
      if (stationConfiguration === undefined) {
        break;
      }

      const values: Services.DeviceModel.StatusValueRef[] = Object.keys(deviceDataset?.values ?? {}).map((identRef) => ({
        identRef,
        value: deviceDataset.values[identRef].value,
        backupValue: undefined,
      }));

      const action = writeStationConfiguration(stationInstanceId, methodStatusRef, stationConfiguration, values);
      api.dispatch(action);
    }
      break;
    case 'CONTACTRON_STATION__MIDDLEWARE__CREATE_REPORT': {
      const { payload } = instanceAction;
      const {
        stationInstanceId, serialNumberValue, userNameValue, userTextValue, type, systemName,
      } = payload;
      const docLanguage: DocumentLanguage = DocumentLanguage[getAppUiLanguageCode().toUpperCase()];

      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const deviceDataset = deviceInstances.instances[stationInstanceId].deviceDataset.user as DatasetState;

      const wizardType = wizardDescSelector(deviceInstances, stationInstanceId);
      if (deviceDataset === undefined || wizardType === undefined) {
        break;
      }

      const wizardState = api.getState().contactronInitWizard as ContactronStationWizardState;
      const activeDeviceState = deviceInstanceActiveDeviceInstance(deviceInstances, stationInstanceId);

      const content: DeviceStartup = {
        type: ReportContentType.DeviceStartup,
        device: {
          deviceDesignation: activeDeviceState?.device.catalog?.deviceTypeName ?? '',
          deviceTag: activeDeviceState?.device.instance?.deviceTag ?? '',
          firmwareRevision: activeDeviceState?.device.instance?.firmwareVersion ?? '',
          hardwareRevision: activeDeviceState?.device.instance?.hardwareVersion ?? '',
          serialNumber: serialNumberValue,
          itemNumber: activeDeviceState?.device.catalog?.productOrderNumber ?? '',
        },
      };

      const report = {
        header: {
          dateTime: new Date(),
          systemName: systemName,
          userName: userNameValue,
          userNotes: userTextValue,
        },
        content,
      };
      const fileName = createReportFilename('ContactronStation', activeDeviceState?.device);
      const reportTable = createWizardReportTable(wizardState, deviceDataset, wizardType.deviceList);

      api.dispatch(updateDeviceDatasetValues(stationInstanceId, {
        targetDataset: DatasetType.user,
        values: [{
          value: reportTable,
          backupValue: undefined,
          identRef: 'CONTACTRON_CONFIGURATION__TABLE',
        }],
      }));

      api.dispatch(createReport(
        {
          targetInstance: stationInstanceId,
          report: {
            documentType: type === 'DOCX' ? DocumentType.DOCX : DocumentType.PDF,
            language: docLanguage,
            reportMenu: wizardType.stationReportMenu,
            reportData: report,
            reportFilename: fileName,
            activeDataset: DatasetType.user,
            compareDataset: DatasetType.device,
            dictFamily: activeDeviceState?.device?.catalog?.i18n.family ?? '',
          },
        },
      ));
    }
      break;
    default:
  }
  const result = next(instanceAction);
  return result;
};

export default contactronWizardMiddleware;
