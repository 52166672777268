/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';
import { groupByDevices, groupByProcessValueNames } from '../../../../wizards/Commissioning/Contactron/Page6GroupProcessData/Table/GroupTable/common';
import { DatasetState } from '../../../deviceInstances/store/deviceDataset/types';
import { ContactronStationWizardState, ContactronWizardGroupMode } from '../../../wizards/contactronStation';
import { RowData } from '../../../../wizards/Commissioning/Contactron/Page6GroupProcessData/Table/GroupTable/GroupTableRow/GroupTableRow';
import { ReportTableRowData, ReportTableRow } from './reportTableInterfaces';

const headers = [
  'WIZARD_CONTACTRON__TABLE__HEADER_POSITION',
  'WIZARD_CONTACTRON__TABLE__HEADER_DEVICE_TAG',
  'WIZARD_CONTACTRON__TABLE__HEADER_IFS_ADDRESS',
  'WIZARD_CONTACTRON__TABLE__HEADER_PDC_INDEX',
  'WIZARD_CONTACTRON__TABLE__HEADER_PROCESS_VALUE_NAME',
  'WIZARD_CONTACTRON__TABLE__HEADER_DEVICE_TYPE',
];

const reportTableData = (tagValue: string, index: number, row: RowData, pdcIndex: number): ReportTableRowData => ({
  data: [
    { value: `${index}` },
    { value: tagValue },
    { value: `${row.ifsAddress}` },
    { value: `${pdcIndex}` },
    { value: row.processValueLabel },
    { value: row.deviceType },
  ],
});

const deviceTagValue = (deviceDataset: DatasetState, deviceTagVarName: string): string => {
  const value = deviceDataset.values[deviceTagVarName]?.value;
  return value === undefined ? '' : value as string;
};

const inputGroupReportTable = (
  state: ContactronStationWizardState,
  deviceDataset: DatasetState,
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType,
): ReportTableRow[] => {
  const moduleCollection = state.modules;
  const { groupMode } = state;

  const stationRowsData = groupByDevices(moduleCollection, stationDeviceDescription, 'input', 'station');

  const rowsData = groupMode === ContactronWizardGroupMode.Device
    ? groupByDevices(moduleCollection, stationDeviceDescription, 'input', 'module')
    : groupByProcessValueNames(moduleCollection, stationDeviceDescription, 'input', 'module');

  const moduleRows = [...stationRowsData, ...rowsData]
    .reduce((acc, row, index) => {
      const pdcIndex = index % 16;
      const tagValue = deviceTagValue(deviceDataset, row.deviceTagVarName);
      return [
        ...acc,
        reportTableData(tagValue, index + 1, row, pdcIndex),
      ];
    }, [] as ReportTableRow[]);

  return moduleRows;
};

const outputGroupReportTable = (
  state: ContactronStationWizardState,
  deviceDataset: DatasetState,
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType,
): ReportTableRow[] => {
  const moduleCollection = state.modules;
  const { groupMode } = state;

  const stationRowsData = groupByDevices(moduleCollection, stationDeviceDescription, 'output', 'station');

  const rowsData = groupMode === ContactronWizardGroupMode.Device
    ? groupByDevices(moduleCollection, stationDeviceDescription, 'output', 'module')
    : groupByProcessValueNames(moduleCollection, stationDeviceDescription, 'output', 'module');

  const moduleRows = [...stationRowsData, ...rowsData]
    .reduce((acc, row, index) => {
      const pdcIndex = index % 15;
      const tagValue = deviceTagValue(deviceDataset, row.deviceTagVarName);
      return [
        ...acc,
        reportTableData(tagValue, index + 1, row, pdcIndex),
      ];
    }, [] as ReportTableRow[]);

  return moduleRows;
};

const createWizardReportTable = (
  state: ContactronStationWizardState,
  deviceDataset: DatasetState,
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType,
): string => {
  const inputRows = inputGroupReportTable(state, deviceDataset, stationDeviceDescription);
  const outputRows = outputGroupReportTable(state, deviceDataset, stationDeviceDescription);

  const reportTable = {
    headers: headers,
    body: {
      inputRowsTitle: 'WIZARD_CONTACTRON__PAGE6_INPUT_PROCESS_DATA__TITLE',
      outputRowsTitle: 'WIZARD_CONTACTRON__PAGE6_OUTPUT_PROCESS_DATA__TITLE',
      inputRows: inputRows,
      outputRows: outputRows,
    },
  };

  return JSON.stringify(reportTable);
};

export default createWizardReportTable;
