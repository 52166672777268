/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CTAButton } from '../../../../components/CTAButton/CTAButton';
import { loadDeviceSimulationConfig } from '../../../../store/deviceSimulation/actions';
import { useTypedSelector } from '../../../../store';
import { ModalSimulationSettingsDialog } from '../../../../modal/ModalSimulationSettings/ModalSimulationSettings';
import './SimulationButton.scss';

const SimulationButton: React.FC = () : React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [enabledSimulationDialog, setSimulationDialog] = useState(false);
  const deviceCatalog = useTypedSelector((state) => state.discoveryService.catalog.deviceList);

  useEffect(() => {
    // start scanning of communication interfaces for connected devices
    dispatch(loadDeviceSimulationConfig());
  }, [deviceCatalog]);

  const simulationDialog = enabledSimulationDialog
    ? (
      <ModalSimulationSettingsDialog closeButtonText="STANDARD_BUTTON__CLOSE" onClose={() => setSimulationDialog(false)} />
    ) : undefined;

  return (
    <>
      <CTAButton
        caption={t('STANDARD_BUTTON__SIMULATION')}
        variant="secondary-small"
        onClick={() => setSimulationDialog(true)}
        customClassName="simulation-button"
        block
      />
      {simulationDialog}
    </>
  );
};

export default SimulationButton;
