/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useEffect } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RestoreDeviceBackupPage from './RestoreDeviceBackupPage/RestoreDeviceBackupPage';
import RestoreDeviceResetTemplatePage from './RestoreDeviceResetTemplatePage/RestoreDeviceResetTemplatePage';
import RestoreDeviceFinalPage from './RestoreDeviceFinalPage/RestoreDeviceFinalPage';
import PageSelectActiveDevice from '../PageCollection/PageSelectActiveDevice/PageSelectActiveDevice';
import {
  deviceWizardUpdateButtons,
  disposeDevice,
  useTypedSelector,
} from '../../store';
import WizardView from '../WizardView/WizardView';
import { ButtonState, ButtonRole } from '../../store/deviceWizard/types';
import { deviceWizardInitButtons, deviceWizardSetButtons } from '../../store/deviceWizard';
import { WIZARD_BUTTON_LABEL__NEXT, WIZARD_BUTTON_LABEL__TRANSFER } from '../helper';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { wizardPreviewMenuSelector, wizardReportMenuSelector } from '../../store/selectors/rootStructureSelector';
import { deviceDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { executeDeviceDownloadDatasetMethod, executeDeviceUploadMethod } from '../../store/deviceInstances/middleware/deviceMethodExecution/actions';
import { ACTIONID__DOWNLOAD_DATA_TO_DEVICE, ACTIONID__UPLOAD_DATA_FROM_DEVICE } from '../../store/deviceInstances/middleware/deviceMethodExecution/types';

const wizardDeviceInstanceId = 'WIZARD_RESTORE_DEVICE__ID';
const WIZARD__ACTIONID__UPLOAD_DATA_FROM_DEVICE = 'ACTION__RESTORE.UPLOAD_DATA_FROM_DEVICE';

const RestoreDeviceWizard: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const datasetModified = useTypedSelector((state) => {
    const deviceDataset = deviceDatasetSelector(state.deviceInstances, wizardDeviceInstanceId);
    return deviceDataset?.user.datasetModified ?? false;
  });
  const reportMenu = useTypedSelector((state) => wizardReportMenuSelector(state.deviceInstances, wizardDeviceInstanceId, DatasetType.user, 'WIZARD_RESTOREDEVICE'));
  const previewMenu = useTypedSelector((state) => wizardPreviewMenuSelector(state.deviceInstances, wizardDeviceInstanceId, DatasetType.user, 'WIZARD_RESTOREDEVICE'));

  useEffect(() => {
    dispatch(deviceWizardInitButtons({
      abort: ButtonState.enabled,
      back: ButtonState.disabled,
      next: ButtonState.disabled,
      complete: ButtonState.hidden,
      pcta: ButtonRole.next,
    }));
  }, []);

  return (
    <WizardView
      title={t<string>('WIZARD_RESTORE_DEVICE__TITLE')}
      onWizardExit={() => {
        dispatch(disposeDevice(wizardDeviceInstanceId));
      }}
    >
      <PageSelectActiveDevice
        title={t<string>('WIZARD_RESTORE_DEVICE__PAGE_SELECT_DEVICE__TITLE')}
        supportedWizard={DeviceModelStatus.Wizards.WizardType.RESTOREDEVICE}
        first
        onChangeToNextPage={() => {
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
            focusPcta: ButtonRole.next,
          }));
        }}
        deviceInstanceId={wizardDeviceInstanceId}
      />
      <RestoreDeviceBackupPage
        title={t<string>('CREATE_BACKUP__TITLE')}
        deviceInstanceId={wizardDeviceInstanceId}
        previewMenu={previewMenu}
        onChangeToNextPage={() => {
          dispatch(deviceWizardSetButtons({
            abort: { state: ButtonState.enabled },
            back: { state: ButtonState.enabled },
            next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__TRANSFER },
            complete: { state: ButtonState.hidden },
            pcta: ButtonRole.next,
            focusPcta: undefined,
          }));
        }}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.disabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
        }}
      />
      <RestoreDeviceResetTemplatePage
        title={t<string>('WIZARD_RESTORE_DEVICE__PAGE_RESTORE_OPTIONS__CAPTION')}
        deviceInstanceId={wizardDeviceInstanceId}
        previewMenu={previewMenu}
        onChangeToNextPage={() => {
          dispatch(executeDeviceDownloadDatasetMethod({
            targetInstance: wizardDeviceInstanceId,
            actionId: ACTIONID__DOWNLOAD_DATA_TO_DEVICE,
            methodIdent: DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF,
            sourceDataset: DatasetType.user,
          }));
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.disabled,
            back: ButtonState.disabled,
            next: ButtonState.hidden,
            complete: ButtonState.disabled,
            pcta: ButtonRole.complete,
          }));
        }}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardSetButtons({
            abort: { state: ButtonState.enabled },
            back: { state: ButtonState.enabled },
            next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__NEXT },
            complete: { state: ButtonState.hidden },
            pcta: ButtonRole.next,
          }));
          dispatch(executeDeviceUploadMethod(wizardDeviceInstanceId, DeviceModelStatus.Methods.METHOD_UPLOAD__IDENTREF, WIZARD__ACTIONID__UPLOAD_DATA_FROM_DEVICE));
        }}
        state={{ error: false, modified: datasetModified }}
      />
      <RestoreDeviceFinalPage
        title={t<string>('WIZARD_RESTORE_DEVICE__PAGE_DOCUMENTATION__CAPTION')}
        reportMenu={reportMenu}
        deviceInstanceId={wizardDeviceInstanceId}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardSetButtons({
            abort: { state: ButtonState.enabled },
            back: { state: ButtonState.enabled },
            next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__TRANSFER },
            complete: { state: ButtonState.hidden },
            pcta: ButtonRole.next,
            focusPcta: ButtonRole.next,
          }));
        }}
      />
    </WizardView>
  );
};

export default RestoreDeviceWizard;
