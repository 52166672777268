/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus, Services } from '@gpt/commons';
import TPFilePageSelectParameterFile from './TPFilePageSelectParameterFile/TPFilePageSelectParameterFile';
import TPFilePageCreateBackup from './TPFilePageCreateBackup/TPFilePageCreateBackup';
import TPFilePageTransferDataset from './TPFilePageTransferDataset/TPFilePageTransferDataset';
import {
  deviceWizardUpdateButtons, useTypedSelector,
} from '../../store';
import PageSelectActiveDevice from '../PageCollection/PageSelectActiveDevice/PageSelectActiveDevice';
import { ButtonRole, ButtonState } from '../../store/deviceWizard/types';
import { deviceWizardInitButtons, deviceWizardSetButtons } from '../../store/deviceWizard';
import { WIZARD_BUTTON_LABEL__NEXT, WIZARD_BUTTON_LABEL__TRANSFER } from '../helper';
import WizardView from '../WizardView/WizardView';
import { wizardPreviewMenuSelector, wizardReportMenuSelector } from '../../store/selectors/rootStructureSelector';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { executeDeviceDownloadMethod } from '../../store/deviceInstances/middleware/deviceMethodExecution/actions';
import { cleanDeviceMethodExecutionState } from '../../store/deviceInstances/store/deviceMethod/actions';
import { deviceInstanceActiveDeviceInstance } from '../../store/deviceInstances/store/activeDevice/selectors';

const wizardDeviceInstanceId = 'WIZARD__TRANSFER_PARAM_FILE__ID';

const TransferParameterFile:React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, wizardDeviceInstanceId)?.device);

  const deviceDatasetValues = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, wizardDeviceInstanceId, DatasetType.user)?.values) ?? {};
  const reportMenu = useTypedSelector((state) => wizardReportMenuSelector(state.deviceInstances, wizardDeviceInstanceId, DatasetType.user, 'WIZARD_TRANSFERPARAMETERFILE'));
  const previewMenu = useTypedSelector((state) => wizardPreviewMenuSelector(state.deviceInstances, wizardDeviceInstanceId, DatasetType.user, 'WIZARD_TRANSFERPARAMETERFILE'));

  const [downloadValues, setDownloadValues] = useState<Services.DeviceModel.StatusValueRef[]>([]);

  useEffect(() => {
    dispatch(deviceWizardInitButtons({
      abort: ButtonState.enabled,
      back: ButtonState.disabled,
      next: ButtonState.disabled,
      complete: ButtonState.hidden,
      pcta: ButtonRole.next,
    }));
  }, []);

  return (
    <WizardView
      title={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__TITLE')}
      onWizardExit={async () => {
        dispatch(cleanDeviceMethodExecutionState(wizardDeviceInstanceId, 'TRANSFER-PARAM-WIZARD--INSTANTIATE-DEVICE-EXTFILE'));
      }}
    >
      <TPFilePageSelectParameterFile
        title={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_SELECT_FILE__TITLE')}
        deviceInstanceId={wizardDeviceInstanceId}
        previewMenu={previewMenu}
        onChangeToNextPage={() => {
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
          // Save device values for download
          const downValues: Services.DeviceModel.StatusValueRef[] = Object.keys(deviceDatasetValues)
            .map((key) => ({
              identRef: key,
              value: deviceDatasetValues[key].value,
              backupValue: undefined,
            }));
          setDownloadValues(downValues);
        }}
      />
      <PageSelectActiveDevice
        title={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_SELECT_DEVICE__TITLE')}
        deviceInstanceId={wizardDeviceInstanceId}
        supportedWizard={DeviceModelStatus.Wizards.WizardType.TRANSFERPARAMETERFILE}
        filter={(device) => {
          if (activeDevice === undefined) {
            return true;
          }
          return (activeDevice.catalog.profileName === device.catalog.profileName);
        }}
        onChangeToNextPage={() => {
          dispatch(deviceWizardSetButtons({
            abort: { state: ButtonState.enabled },
            back: { state: ButtonState.disabled },
            next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__TRANSFER },
            complete: { state: ButtonState.hidden },
            pcta: ButtonRole.next,
          }));
        }}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.disabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
        }}
      />
      <TPFilePageCreateBackup
        title={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_CREATE_BACKUP__TITLE')}
        deviceInstanceId={wizardDeviceInstanceId}
        previewMenu={previewMenu}
        onChangeToNextPage={() => {
          dispatch(executeDeviceDownloadMethod({
            methodIdent: DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF,
            values: downloadValues,
            targetInstance: wizardDeviceInstanceId,
          }));
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.disabled,
            back: ButtonState.disabled,
            next: ButtonState.hidden,
            complete: ButtonState.disabled,
            pcta: ButtonRole.complete,
          }));
        }}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardSetButtons({
            abort: { state: ButtonState.enabled },
            back: { state: ButtonState.enabled },
            next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__NEXT },
            complete: { state: ButtonState.hidden },
            pcta: ButtonRole.next,
          }));
        }}
      />
      <TPFilePageTransferDataset
        title={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_TRANSFER_DATASET__TITLE')}
        reportMenu={reportMenu}
        deviceInstanceId={wizardDeviceInstanceId}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardSetButtons({
            abort: { state: ButtonState.enabled },
            back: { state: ButtonState.enabled },
            next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__TRANSFER },
            complete: { state: ButtonState.hidden },
            pcta: ButtonRole.next,
            focusPcta: ButtonRole.next,
          }));
        }}
      />
    </WizardView>
  );
};

export default TransferParameterFile;
