/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, {
  useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import {
  disposeDevice,
  ExecutionState,
  setActiveDeviceExecutionStatus,
  useTypedSelector,
} from '../../store';
import CPFilePageDocumentation from './CPFilePageDocumentation/CPFilePageDocumentation';
import CPFilePageSelectCatalogDevice from './CPFilePageSelectCatalogDevice/CPFilePageSelectCatalogDevice';
import { ButtonRole, ButtonState } from '../../store/deviceWizard/types';
import ReduxControl from '../../controls/ReduxControl/ReduxControl';
import { deviceWizardInitButtons } from '../../store/deviceWizard';
import { StructureItems } from '../../controls/ReduxControlProps';
import updateWizardPageState from '../helper';
import { compareTabData, MenuItemData, menuItemsSelector } from '../../controls/selectors/menuItemsSelector';
import { DatasetContext } from '../../views/DatasetContext';
import CPFileDevicePage from './CPFileDevicePage';
import WizardView from '../WizardView/WizardView';
import { wizardPreviewMenuSelector } from '../../store/selectors/rootStructureSelector';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';

export interface CreateParameterFileWizardProps {
  deviceInstanceId: string;
}

const CreateParameterFileWizard: React.FC<CreateParameterFileWizardProps> = (props: CreateParameterFileWizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deviceInstanceId } = props;
  const { targetDataset } = useContext(DatasetContext);
  const [pageStates, setPageState] = useState<StructureItems>({});

  useEffect(() => {
    dispatch(deviceWizardInitButtons({
      abort: ButtonState.enabled,
      back: ButtonState.disabled,
      next: ButtonState.disabled,
      complete: ButtonState.hidden,
      pcta: ButtonRole.next,
    }));
  }, []);

  const previewMenu = useTypedSelector((state) => wizardPreviewMenuSelector(state.deviceInstances, deviceInstanceId, targetDataset, 'WIZARD_CREATEPARAMETERFILE'));

  const wizardData: MenuItemData[] = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    const wizardDesc = dataset?.descriptors.WIZARD_CREATEPARAMETERFILE;

    if (wizardDesc?.type !== DeviceModelStatus.StatusType.WizardDescriptor
      || wizardDesc.wizardType.type !== DeviceModelStatus.Wizards.WizardType.CREATEPARAMETERFILE) {
      return [];
    }
    return menuItemsSelector(dataset, wizardDesc.wizardType.menu);
  }, (p, c) => compareTabData(p, c));

  const pages = wizardData === undefined ? undefined : wizardData
    .map((element, index) => (
      <CPFileDevicePage
        title={t<string>(element.label)}
        key={`wiz-cpf-device--${element.identRef}`}
        state={pageStates[element.identRef]}
        identRef={element.identRef}
        onChangeToPreviousPage={() => {
          if (index === 0) {
            dispatch(setActiveDeviceExecutionStatus(deviceInstanceId, ExecutionState.init));
            dispatch(disposeDevice(deviceInstanceId));
          }
        }}
      >
        <ReduxControl
          identRef={element.identRef}
          deviceInstanceId={deviceInstanceId}
          onStateChanged={(ident, pagestate) => setPageState((state) => updateWizardPageState(state, ident, pagestate))}
        />
      </CPFileDevicePage>
    ));

  return (
    <WizardView
      title={t<string>('WIZARD_CREATE_PARAMETER_FILE__TITLE')}
      onWizardExit={() => {
        disposeDevice(deviceInstanceId);
      }}
    >
      <CPFilePageSelectCatalogDevice
        deviceInstanceId={deviceInstanceId}
        title={t<string>('WIZARD_CREATE_PARAMETER_FILE__PAGE_SELECT_DEVICE__TITLE')}
        previewMenu={previewMenu}
        hideStepper
      />
      {pages}
      <CPFilePageDocumentation
        deviceInstanceId={deviceInstanceId}
        title={t<string>('WIZARD_CREATE_PARAMETER_FILE__FINAL_PAGE__TITLE')}
        previewMenu={previewMenu}
      />
    </WizardView>
  );
};

export default CreateParameterFileWizard;
