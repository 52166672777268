/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import i18next, { TFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { Store } from 'redux';
import {
  initInterpolationValues, getInterpolationValue,
} from './services/i18n/i18nVariableInterpolation';
import bundledResources from './assets/i18n';
import { infrastructureService } from './services/InfrastructureService/InfrastructureService';

const Console = console;

/**
 * Default language code string to use when none has been set.
 */
const defaultLanguageCode = 'en';

/**
 * Key for configuration service (and file "app.config.json") to store
 * the language code of the currently configured user interface language.
 */
const languageCodeConfigKey = 'ui.language';

/**
 * Retrieves the persistently stored two-letter language code string.
 * @returns language code string; or null if not persistently stored
 */
export const getStoredLanguageCode = async ()
: Promise<string|undefined> => {
  const configValue = await infrastructureService.getConfigValue(languageCodeConfigKey);
  return configValue !== undefined ? configValue as string : undefined;
};

// /**
// * Persistently stores the passed two-letter language code string.
// * @param languageCode language code string to store
// */
// eslint-disable-next-line max-len
export const setStoredLanguageCode = async (langCode: string)
: Promise<void> => {
  const res = await infrastructureService.setConfigValue(languageCodeConfigKey, langCode);
  if (!res) {
    Console.warn('setStoredLanguageCode failed');
  }
};

/**
 * Gets the currently used language code string of this web application.
 * @returns language code string; or defaultLanguageCode if not available
 * The value is obtained from "i18next.language" which is either set by
 * persistent configuration or by "i18next-browser-languagedetector".
 */
export const getAppUiLanguageCode = (): string => {
  const languageCode = i18next.isInitialized
    ? i18next.language : defaultLanguageCode;
  return languageCode;
};

/**
 * Changes the currently used language code string of this web application.
 * @param languageCode language code string to use for application (→i18next)
 * This is performed by calling "i18next.changeLanguage(…)" to modify labels
 * of React based UI and "setStoredLanguageCode(…)" for persistent storage.
 */
export const setAppUiLanguageCode = (languageCode: string): void => {
  // change current language of UI → React → i18next
  if (i18next.isInitialized) {
    if (i18next.language !== languageCode) {
      i18next.changeLanguage(languageCode);
    }
  }
  // change persistently stored language configuration
  setStoredLanguageCode(languageCode);
};

// Set i18next language, i.e. 'de', 'en', 'en-US', 'cimode', 'dev' or undefined.
// With undefined language value, the "i18next-browser-languagedetector" module
// will automatically detect the language based on browser and/or OS settings.
// Value 'dev' can be used during development to add additional translation keys.
// Value 'cimode' will output the translation key instead of translated values.
// let i18nLanguage: string | undefined;

/**
 * Asynchronous i18next configuration and initialization function.
 *
 * Prior to i18next initialization, this function loads the list of
 * available languages and namespaces from 'i18n' Moleculer service
 * which is implemented in DeviceModelServer.
 * During i18next initialization, according translations are loaded.
 * Afterwards (during runtime) no translations are loaded anymore,
 * then only translation keys with missing translations are reported.
 */
export default async function i18nextConfig(store: Store):
Promise<TFunction> {
  const languages: string[] = Object.keys(bundledResources);
  const supportedLngs = ['dev', ...languages];

  // initialize i18next "interpolation" values to allow special replacements
  // to be used in translations, i.e. "{{USER_NAME}}" or "{{DEVICE_NAME}}"
  initInterpolationValues(store);

  // initialize i18next itself
  return i18next
    .use(initReactI18next)
    .use(new LanguageDetector())
    .use(resourcesToBackend(bundledResources))
    // // To support debugging of internationalization, the following lines
    // // allow to intercept calls to the i18next t() translation function.
    // // For details, see: https://www.i18next.com/misc/creating-own-plugins
    // .use({
    //   type: 'postProcessor',
    //   name: 'i18nCustomDebugHelper',
    //   process(value, key, options, translator) {
    //     Console.log('i18next t()', key, value, options, translator);
    //     return value;
    //   },
    // })
    // .use({
    //   type: 'logger',
    //   log: (args) => {
    //     Console.debug('i18next', ...args); // React+i18next debug is verbose
    //   },
    //   warn: (args) => {
    //     Console.warn('i18next', ...args);
    //   },
    //   error: (args) => {
    //     Console.error('i18next', ...args);
    //   },
    // })
    .init({
      debug: false,
      // Note: with "debug: false" (default setting), i18next only creates log
      //    entries for warning and error messages, but not for info log level
      react: {
        useSuspense: false,
        bindI18n: 'loaded languageChanged',
        bindI18nStore: 'added',
      },
      // detection: {
      //   order: ['localStorage', 'querystring'],
      //   caches: ['localStorage'],
      //   // lookupQuerystring: 'lng',
      //   // lookupCookie: 'i18next',
      //   // lookupLocalStorage: 'i18nextLng',
      // },
      // Note: with "useSuspense: true" (default setting), React App must use
      //    <Suspense fallback={<div>Loading... </div>}><App … /></Suspense>
      // otherwise React initialization will abort with an according exception
      // resources,
      lng: defaultLanguageCode,
      fallbackLng: defaultLanguageCode,
      supportedLngs,
      initImmediate: true,
      ns: ['translation'], // w/o device model specific namespaces
      partialBundledLanguages: true,
      saveMissing: false,
      interpolation: { escapeValue: false },
      missingInterpolationHandler: (text, value) => (
        getInterpolationValue(value[1].trim())
      ),
      // missingKeyHandler: (
      //   lngs: readonly string[],
      //   ns: string,
      //   key: string,
      //   fallbackValue: string,
      //   updateMissing: boolean,
      //   options: any,
      // ): void => {
      //   console.log('missing key', ns, key, fallbackValue, updateMissing, i18next);
      // },
    });

  // i18next configuration options
  // https://www.i18next.com/overview/configuration-options
  //
  //   debug: false,               // Logs info level to console output,
  //                               // helps finding issues with loading not working.
  //   lng: undefined,             // Language to use (overrides language detection),
  //                               // if set to 'cimode' the output text will be the key.
  //   fallbackLng: ['dev'],       // Language to use if translations in user language are
  //                               // not available, or false for no fallback language.
  //   supportedLngs: false,       // array of allowed languages
  //   nonExplicitSupportedLngs: false, // true to pass 'en-US' if finding 'en' in supportedLngs
  //   load: 'all',                // 'all' | 'currentOnly' | 'languageOnly'
  //                               // 'all'          -> ['en-US', 'en', 'dev']
  //                               // 'currentOnly'  ->  'en-US'
  //                               // 'languageOnly' ->  'en'
  //   preload: false,             // array of languages to preload
  //   ns: ['translation'],        // string or array of namespaces to load
  //   defaultNS: ['translation'], // default namespace used if not passed to translation function
  //   fallbackNS: false,          // namespace string or array to lookup key if not found in ns
  //   saveMissing: false,         // calls save missing key function on backend if key not found
  //   saveMissingTo: 'fallback',  // 'fallback' | 'current' | 'all' = language to save missing key
  //   saveMissingPlurals: true,   // will save all forms not only singular key
  //   returnNull: true,           // allows null value as valid translation
  //   returnEmptyString: true,    // allows empty string value as valid translation
  //   returnObjects: false,       // allows objects as valid translation result
  //   joinArrays: false,          // or string to join array, eg. '\n' to join by newline
}
