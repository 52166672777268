import { Resource } from 'i18next';

import i18nEn from './PSR_UNI_en.json';
import i18nDe from './PSR_UNI_de.json';

const PSR_UNI: Resource = {
  en: {
    PSR_UNI: i18nEn,
  },
  de: {
    PSR_UNI: i18nDe,
  },
};

export default PSR_UNI;
