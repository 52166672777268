/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { ReduxControlProps } from '../../ReduxControlProps';
import { PSRConfigurationStep } from './ConfigurationStep/ConfigurationStep';
import './PSRManualControl.scss';

const PSRManualControlCH2: React.FC<ReduxControlProps> = ():React.ReactElement => {
  const steps = [
    'PSR_UNI_L__WHEEL_MANUEL_STEP1',
    'PSR_UNI_L__WHEEL_MANUEL_STEP2',
    'PSR_UNI_L__WHEEL_MANUEL_STEP3',
    'PSR_UNI_L__WHEEL_MANUEL_STEP4',
    'PSR_UNI_L__WHEEL_MANUEL_STEP5',
    'PSR_UNI_L__WHEEL_MANUEL_STEP6',
    'PSR_UNI_L__WHEEL_MANUEL_STEP7',
    'PSR_UNI_L__WHEEL_MANUEL_STEP8',
  ].map((text, index) => (<PSRConfigurationStep key={`${text}`} index={index + 1} text={text} />));

  return (
    <div className="psr-uni__manual_configuration">
      {steps}
    </div>
  );
};

export default PSRManualControlCH2;
