/* eslint-disable max-len */
import { toHex2, toHex4 } from '../../../helpers/functions';
import { updateModuleStateTotalCounters, updateStateTotalCounters } from './helpers';
import {
  ContactronWizardActionTypes,
  ContactronStationWizardState,
  ContactronWizardAddModuleInstanceAction,
  ContactronWizardGroupMode,
  ContactronWizardSetProcessDataCheckboxAction,
  ContactronModule,
  ContactronWizardSetAllDataAction,
  ContactronWizardSetModuleHighlightAction,
  ContactronModuleInstanceState,
  CONTACTRON_STATION__MAX_MODULE_COUNT,
  ContactronModuleCollection,
  ContactronWizardUpdateModuleInstanceAction,
} from './types';

export const contactronModuleKey = (address: number): string => `MOD${address}`;

export const moduleId = (
  vendorId: number,
  familyId: number,
  groupId: number,
  deviceId: number,
  configVersion: number,
): string => {
  const id = `${toHex2(vendorId)}-${toHex2(familyId)}-${toHex2(groupId)}-${toHex2(deviceId)}-${toHex4(configVersion)}`;
  return id.toUpperCase();
};

export const contactronModuleId = (module: ContactronModuleInstanceState)
  : string => {
  const id = moduleId(module.vendorId, module.familyId, module.groupId, module.deviceId, module.configVersion);
  return id.toUpperCase();
};

const stationInstance: ContactronModuleInstanceState = {
  address: 0,
  configVersion: 1,
  deviceId: 18,
  familyId: 1,
  groupId: 1,
  name: 'EM-PNET-GATEWAY-IFS',
  serialNo: '0',
  vendorId: 1,
};

const station: ContactronModule = {
  instance: stationInstance,
  input: {
    dataCheckbox: {},
    totalSelected: 0,
  },
  output: {
    dataCheckbox: {},
    totalSelected: 0,
  },
  moduleId: contactronModuleId(stationInstance),
  type: 'station',
  highlight: false,
};

const initialState: ContactronStationWizardState = {
  modules: {
    MOD0: station,
  },
  moduleList: [contactronModuleKey(0)],
  selectedModule: contactronModuleKey(0),
  groupMode: ContactronWizardGroupMode.Device,
  totalInputSelected: 0,
  totalOutputSelected: 0,
  applyAllData: false,
};

const addModuleReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardAddModuleInstanceAction,
): ContactronStationWizardState => {
  const { payload } = action;
  const { module } = payload;

  const modkey = contactronModuleKey(module.address);
  const find = state.moduleList.find((mod) => mod === modkey);
  if (find !== undefined) {
    return {
      ...state,
      modules: {
        ...state.modules,
        [modkey]: {
          ...state.modules[modkey],
          instance: payload.module,
          highlight: payload.userPressButton,
        },
      },
      // Station Cannot be selected
      selectedModule: state.selectedModule !== contactronModuleKey(0)
        ? state.selectedModule
        : contactronModuleKey(1),
    };
  }

  if (Object.keys(state.modules).length >= CONTACTRON_STATION__MAX_MODULE_COUNT) {
    return state;
  }

  return {
    ...state,
    modules: {
      ...state.modules,
      [modkey]: {
        instance: payload.module,
        input: {
          dataCheckbox: {},
          totalSelected: 0,
        },
        output: {
          dataCheckbox: {},
          totalSelected: 0,
        },
        moduleId: contactronModuleId(payload.module),
        highlight: true,
        type: 'module',
      },
    },
    moduleList: [
      ...state.moduleList,
      modkey,
    ],
  };
};

const updateModuleReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardUpdateModuleInstanceAction,
): ContactronStationWizardState => {
  const { payload } = action;
  const { module } = payload;

  const modkey = contactronModuleKey(module.address);
  return state.modules[modkey] !== undefined
    ? {
      ...state,
      modules: {
        ...state.modules,
        [modkey]: {
          ...state.modules[modkey],
          instance: payload.module,
        },
      },
    } : state;
};

const setModuleHighlightReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardSetModuleHighlightAction,
): ContactronStationWizardState => {
  const { payload } = action;
  const { address, value } = payload;

  const modkey = contactronModuleKey(address);
  return {
    ...state,
    modules: {
      ...state.modules,
      [modkey]: {
        ...state.modules[modkey],
        highlight: value,
      },
    },
  };
};

const setAllDataReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardSetAllDataAction,
): ContactronStationWizardState => {
  const { value, address } = action.payload;
  const key = contactronModuleKey(address);

  if (!value) {
    return {
      ...state,
      applyAllData: value,
    };
  }

  const selectedModule = state.modules[key];

  const updatedModules: ContactronModuleCollection = Object.keys(state.modules)
    .reduce((acc, modId) => {
      if (modId === key || modId === 'MOD0') {
        return acc;
      }

      return {
        ...acc,
        [modId]: {
          ...acc[modId],
          input: {
            ...acc[modId].input,
            dataCheckbox: {
              ...acc[modId].input.dataCheckbox,
              ...selectedModule.input.dataCheckbox,
            },
            totalSelected: selectedModule.input.totalSelected,
          },
          output: {
            ...acc[modId].output,
            dataCheckbox: {
              ...acc[modId].output.dataCheckbox,
              ...selectedModule.output.dataCheckbox,
            },
            totalSelected: selectedModule.output.totalSelected,
          },
        },
      };
    }, state.modules);

  return updateStateTotalCounters({
    ...state,
    modules: updatedModules,
    applyAllData: value,
  });
};

const setModuleProcessInputDataCheckbox = (
  state: ContactronStationWizardState,
  moduleKey: string,
  name: string,
  value: boolean,
): ContactronStationWizardState => {
  const newState: ContactronStationWizardState = {
    ...state,
    modules: {
      ...state.modules,
      [moduleKey]: {
        ...state.modules[moduleKey],
        input: {
          ...state.modules[moduleKey].input,
          dataCheckbox: {
            ...state.modules[moduleKey].input.dataCheckbox,
            [name]: value,
          },
        },
      },
    },
  };
  return updateModuleStateTotalCounters(moduleKey, newState);
};

const setModuleProcessOutputDataCheckbox = (
  state: ContactronStationWizardState,
  moduleKey: string,
  name: string,
  value: boolean,
): ContactronStationWizardState => {
  const newState: ContactronStationWizardState = {
    ...state,
    modules: {
      ...state.modules,
      [moduleKey]: {
        ...state.modules[moduleKey],
        output: {
          ...state.modules[moduleKey].output,
          dataCheckbox: {
            ...state.modules[moduleKey].output.dataCheckbox,
            [name]: value,
          },
        },
      },
    },
  };
  return updateModuleStateTotalCounters(moduleKey, newState);
};

const setModuleProcessInputDataCheckboxReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardSetProcessDataCheckboxAction,
): ContactronStationWizardState => {
  const { payload } = action;
  const {
    address, name, value,
  } = payload;

  if (address === 0 || !state.applyAllData) {
    const moduleKey = contactronModuleKey(address);
    return setModuleProcessInputDataCheckbox(state, moduleKey, name, value);
  }

  return Object.keys(state.modules).reduce((acc, key) => {
    const mod = acc.modules[key];
    return mod.type === 'station'
      ? acc
      : setModuleProcessInputDataCheckbox(acc, key, name, value);
  }, state);
};

const setModuleProcessOutputDataCheckboxReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardSetProcessDataCheckboxAction,
): ContactronStationWizardState => {
  const { payload } = action;
  const {
    address, name, value,
  } = payload;

  if (address === 0 || !state.applyAllData) {
    const moduleKey = contactronModuleKey(address);
    return setModuleProcessOutputDataCheckbox(state, moduleKey, name, value);
  }

  return Object.keys(state.modules).reduce((acc, key) => {
    const mod = acc.modules[key];
    return mod.type === 'station'
      ? acc
      : setModuleProcessOutputDataCheckbox(acc, key, name, value);
  }, state);
};

const setModuleProcessDataCheckboxReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardSetProcessDataCheckboxAction,
): ContactronStationWizardState => {
  const { payload } = action;
  const { kind } = payload;

  return kind === 'input'
    ? setModuleProcessInputDataCheckboxReducer(state, action)
    : setModuleProcessOutputDataCheckboxReducer(state, action);
};

const station0Id = contactronModuleKey(0);

const contactronStationInitWizardReducer = (
  state = initialState,
  action: ContactronWizardActionTypes,
): ContactronStationWizardState => {
  switch (action.type) {
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__CLEANUP':
      return {
        ...state,
        modules: {
          [station0Id]: station,
        },
        moduleList: [station0Id],
        selectedModule: station0Id,
        groupMode: ContactronWizardGroupMode.Device,
        totalInputSelected: 0,
        totalOutputSelected: 0,
      };
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__ADD_MODULE':
      return addModuleReducer(state, action);
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__UPDATE_MODULE':
      return updateModuleReducer(state, action);
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_MODULE_HIGHLIGHT':
      return setModuleHighlightReducer(state, action);
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_GROUP_MODE':
      return {
        ...state,
        groupMode: action.payload,
      };
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_PROCESS_DATA_CHECKBOX':
      return setModuleProcessDataCheckboxReducer(state, action);
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_ALL_DATA':
      return setAllDataReducer(state, action);
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__SELECT_MODULE':
      return {
        ...state,
        selectedModule: contactronModuleKey(action.payload),
      };
    default:
      return state;
  }
};

export default contactronStationInitWizardReducer;
